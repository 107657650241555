import React from 'react';
import cx from 'classnames';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import Button from 'components/Button';

// import styles from './about.module.scss';

const AboutPage = () => {
  return (
    <Layout>
      <SEO title="About" />
      <section className={cx('content')}>
        <h1 className={cx('title', 'content-max-width')}>
          About our Organization
        </h1>
        <div>
          <p> <strong>Green Earth Waldorf School (GE-WS)</strong></p>
        
          <p>
              The goal of GE-WS is to groom a new crop of African female leaders who will be trained to live and promote carbon-neutral sustainable living. 
              GE-WS will additionally run periodic Green capacity building programmes through bootcamps,
              social media campaigns, and lifetime Green mentorship programmes. 
              
              <h3>Our Vision</h3>
              <p>“to be a maiden and leading multilingual, Green, educational and research institute for African girls.</p>  
              
              <h3>Our Mission</h3>
              <p>
                To attract gifted but- at- risk teenage girls from across Africa, provide them with a multilingual
                STEAM education and place them in mentorship programs with the world’s leading climate change professionals from across the globe.
              </p>

              <h3>Objectives of the GE-WS</h3>
              <ul className='secondary'>
                <li>1.	Attract gifted teenage girls from across Africa to pursue a junior and senior secondary school education as well as a two-year A Levels qualification.</li>
                <li>2.	Empower Africa’s future Climate Change leaders across various sectors. </li>
                <li>3.	Promote language and cultural integration among African students.  </li>
                <li>4.	Promote educational experimentation and research for the improvement of Africa’s educational system.</li>
                <li>5.	Provide a place for gifted teenage girls to develop their skills in vocational and technical areas in a safe environment.</li>
              </ul>

          </p>
          <div className="rightColumn">
            <div className="videoWrapper">
              <iframe
                title="GEWS Docu-series Commercial"
                className="youtubeVideo"
                width="560"
                height="300"
                src="https://www.youtube.com/embed/zxxxx"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              />
            </div>
          </div>
          
          <h3>Our Curriculum</h3>
          <p> 
            Because of the GE-WS’ African international school community, the school will offer an international curriculum
            that is recognized in every African country such as the Cambridge Assessment International Education. 
            Our students will have the opportunity to be assessed through the Cambridge IGCSE, O-Level and A-Levels,
            after which they are placed in universities in either their home countries, or on the African continent. 
          </p>
          <p>
            GE-WS will offer a STEAM-oriented curriculum, and also hold frequent student-led research & innovation symposiums, 
            to ignite a passion for STEAM - geared towards providing African solutions to African problems.
            GE-WS will run a research center in collaboration with colleges of education from around Africa, 
            and the world, in order to find the best teaching practices, and pedagogy for K-12 education in Africa.     
          </p>


          <p>
          Sustainability in the Curriculum
          <br />
          GE-WS will integrate Climate Change, carbon neutral living and all other tenets under environmental protection with the school’s 
          main formal curriculum. Learning will be practical and as such, students will be given a host of opportunities to acquire practical 
          hands-on skills in conservation of nature, and environmental protection including  but not limited to Energy and Water Conservation, 
          eco-friendly architecture and production of eco-friendly building materials, carbon neutral organic food production, recycling, lifestyle choices, 
          money and jobs, Erosion and Accretion and all the various tenets in the United Nations guide document for Integrating Climate Change in Education 
          at Primary and Secondary Level. (UNESCO, UNICEF & UNITAR, 2013)
          </p>
          
          <p>
          <Button href="/programs">
            Read more on our Programs
          </Button>
        </p>
        </div>
      </section>
    </Layout>
  );
};

export default AboutPage;
